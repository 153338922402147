import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Select,
  MenuItem,
  useTheme,
  CircularProgress
} from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.svg";
import "./dashboard.scss";
import GridViewIcon from "@mui/icons-material/GridView";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Cardssigned from "../../assets/img/svg/Cardssigned.svg";
import Cardssignedbookmark from "../../assets/img/svg/Cardssignedbookmark.svg";
import nodata from "../../assets/img/nodata.png";
import { Link, useNavigate } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../redux/action/dashboardAction";
import moment from "moment/moment";
import { toast } from "react-toastify";
import activeImage from '../../assets/icons/ActiveImg.svg';
import draftImage from '../../assets/icons/DraftImg.svg';
import inactiveImage from '../../assets/icons/InactiveImg.svg';
import menuIcon from '../../assets/icons/menu.svg';
import { columns } from "./helper";
import { filterOptions } from './helper';
import { getStyles } from './helper'
import { getActiveVacancyTextData } from "../../redux/action/chatbotAction";
import { moveToCurrentQuestionFromDraft } from "../../redux/action/chatbotActions";


const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardData = useSelector((state) => state.dashboard);
  const theme = useTheme();
  const [vacancies, setVacancies] = useState([]);
  const [totalVacancies, setTotalVacancies] = useState(0);
  const [credits, setCredits] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTitle, setSearchTitle] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(searchTitle);
  const [status, setStatus] = useState('');
  const [draftedVacancies, setDraftedVacancies] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  //Debounce Effect for search
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchTitle);
    }, 500)

    return () => { clearTimeout(handler) };
  }, [searchTitle])

  //Calling the dashboard API for the first time when this component mounts
  useEffect(() => {
    setTotalItems(dashboardData?.totalCount);
    const params = {
      companyName: '',
      status: '',
      limit: 10,
      page: 1
    }
    dispatch(getDashboardData(params));
  }, []);

  //Calling the API again on change of dependencies
  useEffect(() => {
    const params = {
      companyName: debouncedSearch,
      status: status === "all" ? "" : status,
      limit: rowsPerPage,
      page: page + 1
    }
    dispatch(getDashboardData(params));
  }, [debouncedSearch, status, rowsPerPage, page])

  //Preparing the TableCell values based on the changes in dashboardData
  useEffect(() => {
    const vacanciesList = dashboardData?.vacancies?.map((vacancy) => {
      return {
        vacancyNumber: "#" + String(vacancy?.vacancyNumber).padStart(5, "0"),
        companyName: vacancy?.companyName,
        Job_Title: "Job Description",
        vacancyLimit: '3',
        companyDescription: vacancy?.companyDescription,
        createdAt: moment(vacancy.createdAt).format("DD/MM/YYYY"),
        status: vacancy?.status,
        vacancyTextId:vacancy?.vacancyTextId!==undefined||vacancy?.vacancyTextId!==null?vacancy?.vacancyTextId:"",
        chatbotSession:vacancy?.chatBotSession
      };
    });
    setVacancies(vacanciesList);
    setTotalVacancies(dashboardData?.dashboard?.vacancyTextGenerated);
    setCredits(dashboardData?.dashboard?.totalCreditsLeft);
    setDraftedVacancies(dashboardData?.dashboard?.draftedVacancy)
  }, [dashboardData]);

  //handle Logout Logic 
  const handleLogout = () => {
    localStorage.clear();
    toast.dismiss();
    toast.success("Logged out successfully");

    navigate("/");
  };

  //handle Search Logic
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTitle(value);
  };

  //handle Status selection from filter
  const handleSelectedStatus = (event) => {
    setStatus(event.target.value);
  }

  //handle pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //handle Navigation to chatbot or vacancyText based on status
  const handleNavigationToChatbot=(vacancyTextId,status,sessionId)=>{
     console.log("Image Clicked",sessionId);
     if(status==="active"){
       dispatch(getActiveVacancyTextData(vacancyTextId));
       navigate('/job/vacancy');
      }
      // if(status==="draft"){
      //   dispatch(moveToCurrentQuestionFromDraft(sessionId));
      //   navigate('/chatbot-update',{state:{sessionId}});
      // }
  }

  return (
    <Box className="dashboard">
      <Box className="drawer flex-column">
        <Stack sx={{ paddingInline: "10%" }}>
          <List>
            <ListItem className="no-li-before" onClick={() => navigate("/")}>
              <img width={174} src={logo} className="" />
            </ListItem>
            <ListItem className="active">
              <GridViewIcon />
              Dashboard
            </ListItem>
            <ListItem className="">
              <svg
                className="svg"
                style={{ margin: "2px" }}
                width="24"
                height="24"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.5 6.26074L9.99997 10.983M9.99997 10.983L1.49997 6.26074M9.99997 10.983L10 20.483M19 15.0415V6.92444C19 6.5818 19 6.41048 18.9495 6.25768C18.9049 6.1225 18.8318 5.99842 18.7354 5.89373C18.6263 5.77539 18.4766 5.69219 18.177 5.52579L10.777 1.41468C10.4934 1.25712 10.3516 1.17834 10.2015 1.14745C10.0685 1.12012 9.93146 1.12012 9.79855 1.14745C9.64838 1.17834 9.50658 1.25712 9.22297 1.41468L1.82297 5.52579C1.52345 5.69219 1.37369 5.77539 1.26463 5.89373C1.16816 5.99842 1.09515 6.1225 1.05048 6.25768C1 6.41048 1 6.5818 1 6.92444V15.0415C1 15.3842 1 15.5555 1.05048 15.7083C1.09515 15.8435 1.16816 15.9676 1.26463 16.0723C1.37369 16.1906 1.52345 16.2738 1.82297 16.4402L9.22297 20.5513C9.50658 20.7089 9.64838 20.7876 9.79855 20.8185C9.93146 20.8459 10.0685 20.8459 10.2015 20.8185C10.3516 20.7876 10.4934 20.7089 10.777 20.5513L18.177 16.4402C18.4766 16.2738 18.6263 16.1906 18.7354 16.0723C18.8318 15.9676 18.9049 15.8435 18.9495 15.7083C19 15.5555 19 15.3842 19 15.0415Z"
                  stroke="inherit"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Drafted job vancancies
            </ListItem>
          </List>
        </Stack>
        <Stack sx={{ paddingInline: "10%" }} className="mt-auto">
          <List>
            <ListItem className="" onClick={() => navigate("/profile")}>
              <SettingsOutlinedIcon />
              Settings
            </ListItem>
            <ListItem onClick={() => handleLogout()}>
              <LogoutOutlinedIcon sx={{ transform: "scaleX(-1)" }} />
              Logout
            </ListItem>
          </List>
        </Stack>
      </Box>
      <Box className="dashboard-content flex-column">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box className="cstm-card cardheader">
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box className="cstm-card flex-column">
              <Box className="d-flex gap-15">
                <Box>
                  <img src={Cardssigned} />
                </Box>
                <Box>
                  <Typography variant="h4" className="fs-16 fw-500">
                    Amount Vacancies Text Generated
                  </Typography>
                  <Typography variant="h4" className="fs-24 fw-400">
                    {totalVacancies}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box className="cstm-card flex-column">
              <Box className="d-flex gap-15">
                <Box>
                  <img src={Cardssignedbookmark} />
                </Box>
                <Box>
                  <Typography variant="h4" className="fs-16 fw-500">
                    Amount of Credits Left
                  </Typography>
                  <Typography variant="h4" className="fs-24 fw-400">
                    {credits}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box className="cstm-card flex-column">
              <Box className="d-flex gap-15">
                <Box>
                  <img src={Cardssigned} />
                </Box>
                <Box>
                  <Typography variant="h4" className="fs-16 fw-500">
                    Drafted Job Vacancies
                  </Typography>
                  <Typography variant="h4" className="fs-24 fw-400">
                    {draftedVacancies}
                  </Typography>
                </Box>
              </Box>
              <Box className="mt-auto">
                <Link
                  className="text-orange fs-14 fw-500 text-underline-none d-flex"
                  style={{ maxWidth: "fit-content" }}
                >
                  View All <ArrowForwardIosIcon className="fs-10 my-auto" />
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ paddingTop: "16px", height: "100%", flex: "1 1 auto" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="h-100">
            <Typography variant="h4" className="fs-16 fw-500">
              Recent Vacancies
            </Typography>
            <Box sx={{ display: 'flex', alignItems: "center", p: 2, justifyContent: "flex-end" }}>

              <input
                className="searchBar"
                type="search"
                placeholder="Search"
                value={searchTitle}
                onChange={(e) => handleSearch(e)}
              />

              <Select
                sx={{ width: "200px", border: "1px solid #F89B53", borderRadius: "30px", backgroundColor: "white", height: "52px", marginLeft: "20px" }}
                value={status}
                onChange={handleSelectedStatus}
                displayEmpty
              >
                <MenuItem value="" disabled><em>Select status</em></MenuItem>
                {filterOptions?.map((ele, index) => (
                  <MenuItem
                    key={index}
                    value={ele}
                    style={getStyles(ele, '', theme)}
                  >
                    {ele}
                  </MenuItem>
                ))}
              </Select>
            </Box>


            <Box className="cstm-card flex-column">
              <Box id="table">
                <Paper
                  sx={{ width: "100%", overflow: "hidden", border: "none" }}
                >
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {dashboardData?.loading ? (
                          <TableRow>
                            <TableCell colSpan={columns.length}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "200px",
                                }}
                              >
                                <CircularProgress sx={{ color: "#F89B53" }} />
                              </Box>
                            </TableCell>
                          </TableRow>
                        ) :
                          vacancies
                            .map((row) => {
                              console.log("row",row)
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.code}
                                >
                                  {columns.map((column) => {
                                    const value = row[column.id];
                                    console.log("value",row);
                                    return (
                                      <TableCell
                                        key={column.id}
                                        align={column.align}
                                      >
                                        {/* <div>   */}
                                        {column?.id === "status" ? <img src={value === "active" ? activeImage : value === "draft" ? draftImage : value === "inActive" ? inactiveImage : ""} style={{ marginRight: "3px"}} /> : ""}
                                        {column.format &&
                                          typeof value === "number"
                                          ? column.format(value)
                                          : value}
                                        {/* </div> */}
                                        
                                      </TableCell>
                                      
                                    );
                                  })}
                                  <img src={menuIcon}  onClick={()=>handleNavigationToChatbot(row?.vacancyTextId,row?.status,row?.chatbotSession)}  style={{ height: "15px", width: "15px", marginTop: "22px", marginRight: "30px",cursor:"pointer"  }} />
                                </TableRow>
                              );
                            })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={totalItems}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}

                  />
                </Paper>
              </Box>
              {vacancies?.length === 0 && (
                <Box className="m-auto text-center">
                  <img width={200} height={110} src={nodata} />
                  <Typography
                    variant="h4"
                    className="fs-18 fw-500 text-center mt-10"
                    sx={{ maxWidth: "300px", color: "#E1E1E1" }}
                  >
                    No data found
                  </Typography>
                  <Typography
                    variant="h4"
                    className="fs-14 fw-400 text-center mt-10"
                    sx={{ maxWidth: "300px", color: "#A6A6A7" }}
                  >
                    Lorem ipsum dolor sit amet, consec consec onsec onsec
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
