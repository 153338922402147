import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const architypes = [
    {
        
        section: "Job Title",
        paragraphs: [
          "Software Developer",
        ],
        bulletpoints: [
         
        ],
        },
    {
        
    section: "StoryTelling",
    paragraphs: [
      "Things happen by doing them. That is what we at NHL Stenden believe. We give our students the opportunity to develop their talents optimally. In a modern research environment, they themselves give direction to their studies and professional growth. We have strong ties with companies and institutions in the region, but also across the border. As an international multi-campus university of applied sciences, we encourage our students to look beyond their own field of study to develop new knowledge and participate in innovative projects.",
      "We create a meeting place where flows of knowledge and cultures come together and where new connections with the work field are created. In an ambitious way, NHL Stenden provides knowledge that works!",
    ],
    bulletpoints: [
     
    ],
    },
  {

    section: "Who are we?",
    paragraphs: [
      "Things happen by doing them. That is what we at NHL Stenden believe. We give our students the opportunity to develop their talents optimally. In a modern research environment, they themselves give direction to their studies and professional growth. We have strong ties with companies and institutions in the region, but also across the border. As an international multi-campus university of applied sciences, we encourage our students to look beyond their own field of study to develop new knowledge and participate in innovative projects.",
      "We create a meeting place where flows of knowledge and cultures come together and where new connections with the work field are created. In an ambitious way, NHL Stenden provides knowledge that works!",
    ],
    bulletpoints: [
      "Student-driven learning in a modern research environment.",
      "Strong connections with regional and international companies.",
      "Encouraging cross-disciplinary and innovative learning.",
      "Fostering a multicultural and collaborative educational community.",
      "Creating practical knowledge applicable to the real-world workforce.",
    ],
  },
  {
    section: "Job requirements",
    paragraphs: [
      "We are looking for a candidate with advanced academic qualifications in the field of Cyber Security. Ideally, you will hold a master’s degree or PhD, allowing you to contribute to our cutting-edge programs with a solid foundation in this critical domain.",
      "In addition to your academic background, you should possess a strong professional network in Cyber Security, both on a national and international scale. Fluency in English, both spoken and written, is essential for this role, as you will be required to communicate effectively in a global environment.",
    ],
    bulletpoints: [
      "You have a master’s degree or PhD in the field of Cyber Security.",
      "You have a relevant (inter)national network in the world of Cyber Security.",
      "You have an excellent command of the English language both orally and in writing.",
    ],
  },
  {
    section: "Acquisition",
    paragraphs: [
      "We are consciously and confidently working together with Effectus -HR to fill this vacancy. That is why Effectus -HR has been granted exclusivity for this vacancy. Given their 93% fulfillment rate, we are convinced that they will also find a more than suitable candidate for this vacancy. Acquisition in response to this vacancy is therefore neither necessary nor desirable.",
    ],
    bulletpoints: [
      
    ],
  },
];


export const handleCopyContent = (vacancyTextData,contentRef2) => {
  let contentToCopy = "";

  for (const [key, value] of Object.entries(vacancyTextData)) {
    if (key === "Job_Title") {
      contentToCopy += `Job Title: ${value}\n\n`;
    } else if (key === "Trending_Titles") {
      contentToCopy += `Trending Titles:\n`;
      value.forEach((item) => {
        for (const [title, description] of Object.entries(item)) {
          contentToCopy += `  - ${title}: ${description}\n`;
        }
      });
      contentToCopy += "\n";
    } else if (
      key === "Elements_of_values" ||
      key === "neuromarketing_principles"
    ) {
      contentToCopy += `${key.replace(/_/g, " ")}:\n`;
      value.forEach((item) => {
        for (const [subKey, subValue] of Object.entries(item)) {
          contentToCopy += `  - ${subKey}: ${subValue}\n`;
        }
      });
      contentToCopy += "\n";
    } else if (key === "Tone_of_voice") {
      continue;
    } else if (typeof value === "object" && !Array.isArray(value)) {
      contentToCopy += `${key.replace(/_/g, " ")}:\n`;
      for (const [subKey, subValue] of Object.entries(value)) {
        contentToCopy += `  - ${subKey}:\n`;
        subValue.forEach((item) => {
          contentToCopy += `    * ${item}\n`;
        });
      }
      contentToCopy += "\n";
    } else if (Array.isArray(value)) {
      contentToCopy += `${key.replace(/_/g, " ")}:\n`;
      value.forEach((item) => {
        contentToCopy += `  - ${item}\n`;
      });
      contentToCopy += "\n";
    } else {
      contentToCopy += `${key.replace(/_/g, " ")}: ${value}\n\n`;
    }
  }

  const content2 = contentRef2.current.innerText;
  const combinedContent = `${contentToCopy}\n${content2}`;

  navigator.clipboard
    .writeText(combinedContent)
    .then(() => {
      toast.success("Copied to clipboard");
      console.log("Content copied to clipboard successfully!");
    })
    .catch((err) => {
      console.error("Failed to copy content: ", err);
    });
};

//handle Tone Of Voice Selection
export const handleToneOfVoice = (tov,vacancyTextData,toneOfVoice,dispatch,handleClose) => {
  const payload = {
    structured_output: JSON.stringify(vacancyTextData),
    tone_of_voice: "",
  };
  switch (tov) {
    case "friendlyTone":
      dispatch(toneOfVoice({ ...payload, tone_of_voice: "Friendly" }));
      handleClose();
      break;
    case "innovativeTone":
      dispatch(toneOfVoice({ ...payload, tone_of_voice: "innovative" }));
      handleClose();
      break;
    case "professionalTone":
      dispatch(toneOfVoice({ ...payload, tone_of_voice: "professional" }));
      handleClose();
      break;
    case "inspirationalTone":
      dispatch(toneOfVoice({ ...payload, tone_of_voice: "inspirational" }));
      handleClose();
      break;
    case "informativeTone":
      dispatch(toneOfVoice({ ...payload, tone_of_voice: "informative" }));
      handleClose();
      break;
    default:
      break;
  }
};


//handle share via email

export const handleShareEmail = (vacancyId,vacancyTextData) => {
  const subject = "Job Vacancy Information";
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const baseUrl = url.origin;
  const jobDetailUrl = `${baseUrl}/sharejob/${vacancyId}`;
  let body = `
          Job Title: ${vacancyTextData?.["Job_Title"]}\n
          We have an exciting opportunity for you!\n\n
          Please copy and paste the following link into your browser to read the full job description: ${jobDetailUrl}
      `;
  const encodedSubject = encodeURIComponent(subject);
  const encodedBody = encodeURIComponent(body);
  console.log('encodedSubject',encodedSubject,encodedBody);
  const mailtoLink = `mailto:to@example.com?subject=${encodedSubject}&body=${encodedBody}`;
  window.location.href = mailtoLink;
};