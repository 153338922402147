import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useDispatch, useSelector } from "react-redux";
import "../job/job.scss";
import editsvg from "../../assets/img/svg/edit.svg";
import editSelected from "../../assets/img/svg/editSelected.svg";
import copysvg from "../../assets/img/svg/copy.svg";
import downloadsvg from "../../assets/img/svg/download.svg";
import sharesvg from "../../assets/img/svg/share.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import JobTitle from "../../assets/img/svg/JobTitle.svg";
import ToneofVoice from "../../assets/img/svg/ToneofVoice.svg";
import Neuromarketing from "../../assets/img/svg/Neuromarketing.svg";
import ElementsofValue from "../../assets/img/svg/ElementsofValue.svg";
import addsvg from "../../assets/img/svg/add.svg";
import sparksvg from "../../assets/img/svg/spark.svg";
import Loader from "../../components/Loader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Otherpopup from "./otherpopup";
import {
  editedVacancyTextData,
  getBeautifiedData,
  toneOfVoice,
} from "../../redux/action/chatbotAction";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Utils from "../../utils/reduxUtils";
import CancellationPopup from "../../common/CancellationPopup";
import { handleCopyContent, handleShareEmail, handleToneOfVoice } from "./helper";
const Vacancy = () => {
  const [customTone, setCustomTone] = useState("");
  const [openToneDialog, setOpenToneDialog] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [editedVacancyText, setEditedVacancyText] = useState(null);
  const { chatbotDataLoading, vacancyText, vacancyId } = useSelector(
    (state) => state?.jobDescriptionFileUploadReducer
  );
  const dispatch = useDispatch();
  const contentRef1 = useRef(null);
  const contentRef2 = useRef(null);
  const isEditFocus = useRef(null);
  const copyStoryTellingRef = useRef(null);
  const copyAboutTheFunctionRefs = useRef({});
  const copyCriteriaContentRefs = useRef({});
  const copyEmploymentConditionsContentRefs = useRef({});

  const [isCancellationPopupOpen, setIsCancellationPopupOpen] = useState(false);
  const vacancyTextData = vacancyText[0]?.vacancyText
    ? vacancyText[0]?.vacancyText
    : vacancyText[0];
  const [aboutTheFunction, setAboutTheFunction] = useState({});
  const [criteria, setCriteria] = useState({});
  const [employmentConditions, setEmploymentConditions] = useState({});
  const [trendingTitles, setTrendingTitles] = useState([]);
  const [trendingTitlesTooltipValue, setTrendingTitlesTooltipValue] =
    useState("");
  const [elementOfValues, setElementOfValues] = useState([]);
  const [elementOfTooltipValues, setElementOfTooltipValues] = useState({});
  const [neuromarketingValues, setNeuroMarketingValues] = useState([]);
  const [neuromarketingTooltipValues, setNeuromarketingTooltipValues] =
    useState([]);
  const [storeEditedData, setStoreEditedData] = useState(
    editedVacancyText !== null ? editedVacancyText : vacancyTextData
  );
  let maxKey = null;
const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenToneDialog(false);
    setCustomTone("");
  };
  const handleChange = (value) => {
    if (value === "others") {
      setOpenToneDialog(true);
    }
  };

  //handle Tone Of voice selection
  const handleToneOfVoiceSelection=(tov)=>{
      handleToneOfVoice(tov,vacancyTextData,toneOfVoice,dispatch,handleClose) 
}

 const handleOpenPopup = () => {
    setIsCancellationPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsCancellationPopupOpen(false);
  };

  const handleOption1Click = () => {
    // Perform action for Option 1
    setEditedVacancyText(null);
    setIsEditEnabled(false);
    handleClosePopup();
  };

  const handleOption2Click = () => {
    // Perform action for Option 2
    handleClosePopup();
  };

 //handle copy functionality of complete content
  const handleCompleteContentCopy=()=>{
      handleCopyContent(vacancyTextData,contentRef2);
  }

  const handleEditData = (key, value) => {
    setEditedVacancyText((prevstate) => ({
      ...prevstate,
      [key]: value,
    }));
    setStoreEditedData((prevstate) => ({
      ...prevstate,
      [key]: value,
    }));
  };

  const handleFunctionEditData = (key, value) => {
    console.log("key12", key);
    setEditedVacancyText((prevState) => ({
      ...prevState,
      About_The_Function: {
        ...prevState["About_The_Function"],
        [key]: value,
      },
    }));
  };

  const handleCriteriaEditData = (key, value) => {
    console.log("key12", key);
    setEditedVacancyText((prevState) => ({
      ...prevState,
      Criteria: {
        ...prevState["Criteria"],
        [key]: value,
      },
    }));
  };
  const handleEmploymentEditData = (key, value) => {
    console.log("key12", key);
    setEditedVacancyText((prevState) => ({
      ...prevState,
      Employment_Condition: {
        ...prevState["Employment_Condition"],
        [key]: value,
      },
    }));
  };
  //Save Edited Data
  const handleSubmit = () => {
    const payload = {
      id: vacancyId,
      vacancyText: editedVacancyText,
    };
    dispatch({
      type: Utils.ActionName.CHATBOTDATA,
      payload: editedVacancyText,
    });
    dispatch(
      editedVacancyTextData(payload, setEditedVacancyText, setIsEditEnabled)
    );
    setStoreEditedData(editedVacancyText);
    setIsEditEnabled(false);
    toast.success("Text Edited Successfully");
  };
  
  //handle Edit Mode
const handleEditMode = () => {
    if (!isEditEnabled) {
      setEditedVacancyText(vacancyTextData);
      setStoreEditedData(vacancyTextData); // Set the text only when enabling edit mode
    }
    setIsEditEnabled(!isEditEnabled); // Toggle the edit mode state
  };

//handle Job description share functionality
const handleShareByEmail=()=>{
  handleShareEmail(vacancyId,vacancyTextData)
}

useEffect(() => {
    if (vacancyTextData) {
     
  let maxLength = 0;
  let maxKeyValue = "";
  // Iterate through the object to find the array with the maximum length
  for (let key in vacancyTextData) {
    if (
      Array.isArray(vacancyTextData[key]) &&
      vacancyTextData[key].length > maxLength
    ) {
      maxKey = key;
      maxLength = vacancyTextData[key].length;
      maxKeyValue = vacancyTextData[maxKey];
    }
  }
      setAboutTheFunction(vacancyTextData?.About_The_Function);
      setCriteria(vacancyTextData?.Criteria);
      setEmploymentConditions(vacancyTextData?.Employment_Condition);
      const Keys = Object.keys(vacancyTextData?.Trending_Titles);
      const trendingTitles = vacancyTextData?.Trending_Titles;
      const datas = trendingTitles.map((data) => Object.keys(data).join(","));
      console.log("datas", datas);
      setTrendingTitles(datas.join(", "));
      const trendingValues = trendingTitles
        ?.map((data) => Object.values(data))
        .flat()
        .join(" ");
      console.log("trendingValues", trendingValues);
      const sentencesArray = trendingValues.split('.');

// Step 2: Remove empty entries and rejoin with <br /> for line breaks
    const formattedString = sentencesArray
  .filter(sentence => sentence.trim() !== "") // Filter out any empty strings from the split
  .map(sentence => sentence.trim())           // Trim each sentence
  .join(".<br />");         
      setTrendingTitlesTooltipValue(formattedString);
      // Initializing an array to store the tooltip values
      const trendingTitlesTooltipValue = [];

      // Iterating over the keys to extract and append values
      Keys.forEach((ele) => {
        // Access the value associated with the current key and store it in an array
        trendingTitlesTooltipValue.push(vacancyTextData?.Trending_Titles[ele]);
      });

      const elements = vacancyTextData?.Elements_of_values || [];
      const keysArray = [];
      const valuesArray = [];

      elements.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          keysArray.push(key);
          valuesArray.push(value);
        });
      });

      console.log('valuesArray',valuesArray);
      setElementOfValues(keysArray.join(", "));
      setElementOfTooltipValues(valuesArray.join("<br />"));

      const neuromarketing = vacancyTextData?.neuromarketing_principles || [];
      const neuromarketingArray = [];
      const neuromarketingValuesArray = [];

      neuromarketing.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          neuromarketingArray.push(key);
          neuromarketingValuesArray.push(value);
        });
      });
      setNeuroMarketingValues(neuromarketingArray.join(", "));
      setNeuromarketingTooltipValues(neuromarketingValuesArray.join("<br />"));
    }
    setEditedVacancyText(vacancyTextData);
  }, [vacancyTextData]);

  

  //handleBeautifyTemplate
  const handleBeautifyTemplate = () => {
    const payload = {
      structured_output: JSON.stringify(storeEditedData),
    };
    dispatch(getBeautifiedData(payload));
  };

  //Focus Job Title Field when edit is enabled
  useEffect(() => {
    if (isEditEnabled && isEditFocus?.current) {
      isEditFocus?.current?.focus();
    }
  }, [isEditEnabled]);

  //Title Content Copy
  const handleCopyJobTitleContent=()=>{
    console.log("copy clicked");
    if (isEditFocus.current) {
      const textToCopy =
        editedVacancyText?.["Job_Title"] !== undefined
          ? editedVacancyText?.["Job_Title"]
          : vacancyTextData?.["Job_Title"];
      navigator.clipboard.writeText(textToCopy).then(() => {
        toast.success("Text copied to clipboard!");
      });
    }
  }
  //Story Telling Content Copy
  const handleCopyStoryTellingContent=()=>{
    console.log("copy clicked");
    if (copyStoryTellingRef.current) {
      const textToCopy =
      editedVacancyText?.Storytelling !== undefined
      ? editedVacancyText?.Storytelling
      : vacancyTextData?.Storytelling

      navigator.clipboard.writeText(textToCopy).then(() => {
        toast.success("Text copied to clipboard!");
      });
    }
  }

  //handle About the function Content
  const handleCopyAboutTheFunctionContent = () => {
    // Collect all the text from 'About_The_Function' object
    let textToCopy = Object.keys(aboutTheFunction).map((maxKey) => {
      const content = 
        editedVacancyText?.["About_The_Function"]?.[maxKey] ||
        vacancyTextData?.["About_The_Function"]?.[maxKey];
      return content?.join("\n") || ""; // Join each array by newlines
    }).join("\n\n"); // Add extra newlines between different sections
  
    console.log('textToCopy:', textToCopy);
  
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        toast.success("Text copied to clipboard!");
      });
    } else {
      console.log("No content available to copy.");
    }
  };

   //handle Criteria Content Copy
   
   const handleCopyCriteriaContent = () => {
    // Collect all the text from 'About_The_Function' object
     let textToCopy = Object.keys(criteria).map((maxKey) => {
       const content = 
         editedVacancyText?.["Criteria"]?.[maxKey] ||
         vacancyTextData?.["Criteria"]?.[maxKey];
       return content?.join("\n") || ""; // Join each array by newlines
     }).join("\n\n"); // Add extra newlines between different sections
   
    if (textToCopy) {
       navigator.clipboard.writeText(textToCopy).then(() => {
         toast.success("Text copied to clipboard!");
       });
     } else {
       console.log("No content available to copy.");
     }
   };

   //handle employment conditions content copy
   const handleCopyEmploymentConditionsContent = () => {
  // Collect all the text from 'About_The_Function' object
    let textToCopy = Object.keys(employmentConditions).map((maxKey) => {
      const content = 
        editedVacancyText?.["Employment_Condition"]?.[maxKey] ||
        vacancyTextData?.["Employment_Condition"]?.[maxKey];
      return content?.join("\n") || ""; // Join each array by newlines
    }).join("\n\n"); // Add extra newlines between different sections
  if (textToCopy) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        toast.success("Text copied to clipboard!");
      });
    } else {
      console.log("No content available to copy.");
    }
  };
 
  
  


  return (
    <Box>
      {chatbotDataLoading ? (
        <Loader />
      ) : (
        <>
          <Otherpopup
            open={openToneDialog}
            customTone={customTone}
            setCustomTone={setCustomTone}
            handleClose={handleClose}
          ></Otherpopup>
          <CancellationPopup
            open={isCancellationPopupOpen}
            handleClose={handleClosePopup}
            title={"Confirmation Popup"}
            question={"Are You Sure You Want To Discard?"}
            option1={"Yes"}
            option2={"No"}
            onOption1Click={handleOption1Click}
            onOption2Click={handleOption2Click}
          />
          <Header />
          <Box className="py-2 mt-10">
            <Grid container spacing={2} className="px-5">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className=" py-2">
                <Typography variant="h2" className="fs-32 fw-600 px-4">
                  Job Vacancy Text
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                className=" py-2 flex-wrap justify-content-end"
              >
                <Box className="gap-10 flex-wrap">
                  <Box className="flex-wrap justify-content-end">
                    {
                      isEditEnabled?
                      <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={editSelected}
                      onClick={handleEditMode}
                      style={{ cursor: "pointer", borderRadius: "5px" }}
                    /> :
                    <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={editsvg}
                      onClick={handleEditMode}
                      style={{ cursor: "pointer", borderRadius: "5px" }}
                    /> 
                    }
                    <Divider
                      className="mx-2"
                      orientation="vertical"
                      flexItem
                      sx={{ border: "1px solid black" }}
                    />
                    <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={copysvg}
                      onClick={handleCompleteContentCopy}
                      style={{ cursor: "pointer" }}
                    />
                    <Divider
                      className="mx-2"
                      orientation="vertical"
                      flexItem
                      sx={{ border: "1px solid black" }}
                    />
                    {/* <img className='my-auto' width={27} height={27} src={downloadsvg} style={{cursor:"pointer"}}/> */}

                    <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={downloadsvg}
                      style={{ cursor: "pointer" }}
                      alt="Download PDF"
                      title="Download PDF"
                    />

                    <Divider
                      className="mx-2"
                      orientation="vertical"
                      flexItem
                      sx={{ border: "1px solid black" }}
                    />
                    <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={sharesvg}
                      style={{ cursor: "pointer" }}
                      onClick={handleShareByEmail}
                    />
                    <Divider
                      className="mx-2"
                      orientation="vertical"
                      flexItem
                      sx={{ border: "1px solid black" }}
                    />
                    <Box className="flex-wrap gap-10">
                      <div>
                        <Button
                          className="primary-btn-outline gap-10"
                          sx={{ minWidth: "fit-content" }}
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                        >
                          <img src={sparksvg} />
                          Tone of Voice <KeyboardArrowDownIcon />
                        </Button>

                        <Menu
                          className="toneofvoice"
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem
                            onClick={() => handleToneOfVoiceSelection("friendlyTone")}
                          >
                            Friendly Tone
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleToneOfVoiceSelection("innovativeTone")}
                          >
                            Innovative Tone
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleToneOfVoiceSelection("professionalTone")
                            }
                          >
                            Professional Tone
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleToneOfVoiceSelection("inspirationalTone")
                            }
                          >
                            Inspirational Tone
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleToneOfVoiceSelection("informativeTone")}
                          >
                            Informative Tone
                          </MenuItem>
                          <MenuItem onClick={() => handleChange("others")}>
                            Others
                          </MenuItem>
                        </Menu>
                      </div>

                      <Link
                        to={`/job/upload/${vacancyId}`}
                        state={{ editedData: editedVacancyText }}
                      >
                        {" "}
                        <Button
                          className="primary-btn-outline gap-10"
                          sx={{ minWidth: "fit-content" }}
                        >
                          <img src={addsvg} />
                          Generate the Social Media Template{" "}
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={9} className="">
                <Box className="vacancy-content" ref={contentRef1}>
                  <Typography
                    className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                    variant="h3"
                  >
                    Job Title 
                    <span>                
                    <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={copysvg}
                      onClick={handleCopyJobTitleContent}
                      style={{ cursor: "pointer" }}
                    />
                    </span>
                  </Typography>
                  <TextareaAutosize
                   ref={isEditFocus}
                    // ref={isEditFocus}
                    className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                    aria-label="minimum height"
                    minRows={1}
                    placeholder="Enter Job Title"
                    style={{ width: "100% " }}
                    value={
                      editedVacancyText?.["Job_Title"] !== undefined
                        ? editedVacancyText?.["Job_Title"]
                        : vacancyTextData?.["Job_Title"]
                    }
                    disabled={!isEditEnabled}
                    onChange={(e) =>
                      handleEditData("Job_Title", e.target.value)
                    }
                   />
                  <Typography
                    className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                    variant="h3"
                  >
                    Story Telling
                    <span>
                    <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={copysvg}
                      onClick={handleCopyStoryTellingContent}
                      style={{ cursor: "pointer" }}
                    />
                    </span>
                  
                  </Typography>
                  <TextareaAutosize
                  ref={copyStoryTellingRef}
                    className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                    aria-label="minimum height"
                    minRows={1}
                    placeholder="Enter Story Telling"
                    style={{ width: "100% " }}
                    disabled={!isEditEnabled}
                    value={
                      editedVacancyText?.Storytelling !== undefined
                        ? editedVacancyText?.Storytelling
                        : vacancyTextData?.Storytelling
                    }
                    onChange={(e) =>
                      handleEditData("Storytelling", e.target.value)
                    }
                  />
               
                  {Object.keys(aboutTheFunction).map((maxKey) => (
                    <div key={maxKey}>
                      <Typography
                        className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                        variant="h3"
                      >
                        {maxKey}
                      <span>
                      <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={copysvg}
                      onClick={handleCopyAboutTheFunctionContent} 
                      style={{ cursor: "pointer" }}
                    />
                      </span>
                       
                      </Typography>

                      {isEditEnabled ? (
                        <TextareaAutosize
                        ref={(el) => (copyAboutTheFunctionRefs.current[maxKey] = el)}
                          className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                          aria-label="minimum height"
                          minRows={1}
                          placeholder="Enter About The Function"
                          style={{ width: "100%" }}
                          value={
                            (
                              editedVacancyText?.["About_The_Function"]?.[
                                maxKey
                              ] ||
                              vacancyTextData?.["About_The_Function"]?.[maxKey]
                            )?.join("\n") || ""
                          }
                          onChange={(e) =>
                            handleFunctionEditData(
                              maxKey,
                              e.target.value.split("\n")
                            )
                          }
                        />
                      ) : (
                        <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                          {aboutTheFunction[maxKey]?.map((item, index) => (
                            <ListItem
                              key={index}
                              className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                              ref={(el) => (copyAboutTheFunctionRefs.current[maxKey] = el)}
                              style={{ display: 'list-item', listStylePosition: 'inside' ,color:"black"}}
                            >
                              {item}
                            </ListItem>
                          ))}
                        </List>
                  )}
                  </div>
                   ))}
                
                  {Object.keys(criteria).map((criteriaKey) => (
                    <div key={criteriaKey}>
                      <Typography
                        className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                        variant="h3"
                      >
                        {criteriaKey}
                        <span>
                        <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={copysvg}
                      onClick={handleCopyCriteriaContent} 
                      style={{ cursor: "pointer" }}
                    />
                        </span>  
                       
                      </Typography>

                      {isEditEnabled ? (
                        <TextareaAutosize
                        ref={(el) => (copyCriteriaContentRefs.current[maxKey] = el)}
                          className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                          aria-label="minimum height"
                          minRows={1}
                          placeholder="Enter Job Criteria"
                          style={{ width: "100%" }}
                          value={
                            (
                              editedVacancyText?.["Criteria"]?.[criteriaKey] ||
                              vacancyTextData?.["Criteria"]?.[criteriaKey]
                            )?.join("\n") || ""
                          }
                          onChange={(e) =>
                            handleCriteriaEditData(
                              criteriaKey,
                              e.target.value.split("\n")
                            )
                          } 
                        />
                      ) : (
                        <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                          {criteria[criteriaKey]?.map((item, index) => (
                            <ListItem
                            ref={(el) => (copyCriteriaContentRefs.current[maxKey] = el)}
                              key={index}
                              className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                              style={{ display: 'list-item', listStylePosition: 'inside' ,color:"black"}}
                            >
                              {item}
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </div>
                  ))} 
                
                  {Object.keys(employmentConditions).map((employmentKey) => (
                    <div key={employmentKey}>
                      <Typography
                        className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                        variant="h3"
                      >
                        {employmentKey}
                        <span>
                        <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={copysvg}
                      onClick={handleCopyEmploymentConditionsContent} 
                      style={{ cursor: "pointer" }}
                    />
                        </span>
                      </Typography>

                      {isEditEnabled ? (
                        <TextareaAutosize
                         ref={(el) => (copyEmploymentConditionsContentRefs.current[maxKey] = el)}
                          className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                          aria-label="minimum height"
                          minRows={1}
                          placeholder="Enter Employment Conditions"
                          style={{ width: "100%" }}
                          value={
                            (
                              editedVacancyText?.["Employment_Condition"]?.[
                                employmentKey
                              ] ||
                              vacancyTextData?.["Employment_Condition"]?.[
                                employmentKey
                              ]
                            )?.join("\n") || ""
                          }
                          onChange={(e) =>
                            handleEmploymentEditData(
                              employmentKey,
                              e.target.value.split("\n")
                            )
                          } 
                        />
                      ) : (
                        <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                          {employmentConditions[employmentKey]?.map(
                            (item, index) => (
                              <ListItem
                               ref={(el) => (copyEmploymentConditionsContentRefs.current[maxKey] = el)}
                                key={index}
                                className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                                style={{ display: 'list-item', listStylePosition: 'inside' ,color:"black"}}
                              >
                                {item}
                              </ListItem>
                            )
                          )}
                        </List>
                      )}
                    </div>
                  ))}

                </Box>
                <Box className="flex-wrap justify-content-between my-30">
                  <Button
                    className="primary-btn "
                    onClick={handleBeautifyTemplate}
                  >
                    Beautify Template{" "}
                  </Button>
                  <Box>
                    <Button
                      onClick={() => {
                        handleOpenPopup();
                      }}
                      className="primary-btn-outline mr-10"
                      sx={{ minWidth: "144px" }}
                    >
                      Discard
                    </Button>
                    <Button
                      disabled={!isEditEnabled}
                      onClick={handleSubmit}
                      className="primary-btn"
                      sx={{ minWidth: "144px" }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                <Box
                  sx={{
                    background: "#FFF3E9",
                    height: "100%",
                    padding: "28px 24px ",
                  }}
                  ref={contentRef2}
                >
                  <Box>
                    <Box className="d-flex justify-content-between">
                      <Typography
                        variant="h4"
                        className="fs-18 text-color-c1 fw-600 text-start"
                      >
                        <img width={24} height={24} src={JobTitle} />
                        &nbsp; Job Title
                      </Typography>
                      <Tooltip
                        className="text-orange"
                        title={<span dangerouslySetInnerHTML={{ __html: trendingTitlesTooltipValue }} />}
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      className="bg-white mt-10 mb-10"
                      sx={{ padding: "12px 14px" }}
                    >
                      <Typography className="fs-16 fw-500 color-primary">
                        {trendingTitles}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box className="d-flex justify-content-between">
                      <Typography
                        variant="h4"
                        className="fs-18 text-color-c1 fw-600 text-start"
                      >
                        <img width={24} height={24} src={ElementsofValue} />
                        &nbsp; Elements of Value
                      </Typography>
                      <Tooltip
                        className="text-orange"
                        title={<span dangerouslySetInnerHTML={{ __html: elementOfTooltipValues }}/>}
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      className="bg-white mt-10 mb-10"
                      sx={{ padding: "12px 14px" }}
                    >
                      <Typography className="fs-16 fw-500 color-primary">
                        {elementOfValues}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box className="d-flex justify-content-between">
                      <Typography
                        variant="h4"
                        className="fs-18 text-color-c1 fw-600 text-start"
                      >
                        <img width={24} height={24} src={Neuromarketing} />
                        &nbsp; Neuromarketing{" "}
                      </Typography>
                      <Tooltip
                        className="text-orange"
                        title={<span dangerouslySetInnerHTML={{ __html: neuromarketingTooltipValues }}/>}
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      className="bg-white mt-10 mb-10"
                      sx={{ padding: "12px 14px" }}
                    >
                      <Typography className="fs-16 fw-500 color-primary">
                        {neuromarketingValues}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box className="d-flex justify-content-between">
                      <Typography
                        variant="h4"
                        className="fs-18 text-color-c1 fw-600 text-start"
                      >
                        <img width={24} height={24} src={ToneofVoice} />
                        &nbsp; Tone of Voice{" "}
                      </Typography>
                      <Tooltip
                        className="text-orange"
                        title={vacancyTextData?.Tone_of_voice}
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      className="bg-white mt-10 mb-10"
                      sx={{ padding: "12px 14px" }}
                    >
                      <Typography className="fs-16 fw-500 color-primary">
                        {vacancyTextData?.Tone_of_voice}{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Footer />
        </>
      )}
    </Box>
  );
};

export default Vacancy;
