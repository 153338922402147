import { Box, Button, CircularProgress, Dialog, Divider, Grid, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ColorizeIcon from '@mui/icons-material/Colorize';
import plogo from "../assets/img/plogo.png"
import CancelIcon from '@mui/icons-material/Cancel';
import { MuiColorInput } from 'mui-color-input';
import Glider from 'glider-js';
import 'glider-js/glider.min.css';
import sliderimg1 from '../assets/img/start.jfif';
import sliderimg2 from '../assets/img/sliderimg2.jfif';
import sliderimg3 from '../assets/img/sliderimg3.jfif';
import sliderimg4 from '../assets/img/sliderimg4.jfif';
import PlaceIcon from '@mui/icons-material/Place';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import * as htmlToImage from 'html-to-image';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import JSZipUtils from 'jszip-utils';
import JSZip, { file } from 'jszip';
import Loader from './Loader';
import { LogoDev } from '@mui/icons-material';
import { saveAndShareAPI } from '../redux/action/chatbotAction';


const Socialmediapopup = ({vacancyId,template,regenerate,open,onClose,uploadedImage,selectedImages,logo}) => {
        const [loading, setLoading] = useState(true);
        const [downloadingFile, setDownloadingFile] = useState(false);
        const [dowloadingImageAndFile, setDowloadingImageAndFile] = useState(false);
        const [value, setValue] = React.useState('#4543a8');
        const [activeIndex, setActiveIndex] = useState(0);
        const gliderRef = useRef(null);
        const {socialMediaText,chatbotLoading}=useSelector((state)=>state?.jobDescriptionFileUploadReducer)
        const dispatch = useDispatch()
        const [currentSlide, setCurrentSlide] = useState(0);
        const [totalSlides, setTotalSlides] = useState(0);
        useEffect(() => {
          if (open) {
              setTimeout(()=>{
                  new Glider(gliderRef.current, {
                      slidesToShow: 1,
                      scrollLock: true,
                      draggable: true,
                      dots: '#dots',
                      arrows: {
                        prev: '.glider-prev',
                        next: '.glider-next'
                      }
                    });
                     // Event listener to capture the active index when a slide becomes visible
      gliderRef.current.addEventListener('glider-slide-visible', function (event) {
        setActiveIndex(event.detail.slide);
        setTotalSlides(gliderRef.current.children.length);
      });
              },100)
             
              setLoading(false);
          }
        }, [open]);
        let templateImages=[];  
        if(uploadedImage!=null){
            templateImages=[...selectedImages,...uploadedImage];
            }
         else{
            templateImages=[...selectedImages]
         }   
          

          const handleColorChange = (newValue) => {
            setValue(newValue)
          }

          const handleDownloadImage = async () => {
            setDowloadingImageAndFile(true);
            const slides = document.querySelectorAll('.combined-box');
            const zip = new JSZip();
            
            try {
              // Create an array of promises for image generation
              const imagePromises = Array.from(slides).map((slide, index) =>
                htmlToImage.toPng(slide, { backgroundColor: 'white' })
                  .then((dataUrl) => {
                    // Add each image to the ZIP file
                    zip.file(`slide-${index + 1}.png`, dataURLtoBlob(dataUrl));
                  })
              );
             const docBlob= await generateTextFile();
             zip.file(`captions.doc`, docBlob);
              
              // Wait for all image generation promises to resolve
              await Promise.all(imagePromises);
              
              // Generate the ZIP file
              zip.generateAsync({ type: 'blob' })
                .then((content) => {
                  // Save the ZIP file
                  saveAs(content, 'slides.zip');
                  // generateAndDownloadTextFile();
                  setDowloadingImageAndFile(false);
                });
            } catch (error) {
              console.error('Error generating images or ZIP!', error);
            }
          };
          const generateTextFile = async() => {
            setDownloadingFile(true);
            let textContent = '';
        
            // Loop through the template and build the content
            Object?.keys(template).forEach(key => {
              textContent += `Heading: ${key}\n`;
              textContent += `Content: ${template[key]}\n\n`;
            });
        
            // Create a new Blob with the text content
            const blob = new Blob([textContent], { type: 'text/plain' });
            return blob;
            // Create a link element, set it to point to the Blob URL
            
          };
          // Helper function to convert data URL to Blob
          const dataURLtoBlob = (dataUrl) => {
            const [header, data] = dataUrl.split(',');
            const mime = header.match(/:(.*?);/)[1];
            const byteString = atob(data);
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const uint8Array = new Uint8Array(arrayBuffer);
          
            for (let i = 0; i < byteString.length; i++) {
              uint8Array[i] = byteString.charCodeAt(i);
            }
          
            return new Blob([uint8Array], { type: mime });
          };
          const loadFile = (url, callback) => {
            JSZipUtils.getBinaryContent(url, callback);
          };
          const generateAndDownloadTextFile = () => {
            setDownloadingFile(true);
            let textContent = '';
        
            // Loop through the template and build the content
            Object?.keys(template).forEach(key => {
              textContent += `Heading: ${key}\n`;
              textContent += `Content: ${template[key]}\n\n`;
            });
        
            // Create a new Blob with the text content
            const blob = new Blob([textContent], { type: 'text/plain' });
        
            // Create a link element, set it to point to the Blob URL
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'LinkedIn_Posts.txt';  // Set the desired file name
        
            // Programmatically click the link to trigger the download
            link.click();
        
            // Clean up the URL object
            URL.revokeObjectURL(link.href);
            setDownloadingFile(false);
          };
          
            // Reusable function to open mail client with pre-filled data
            const sendMail = () => {
                const recipient = '';
                const currentUrl = window.location.href;
                const url = new URL(currentUrl);
                const baseUrl = url.origin;
                const subject = 'Check out this Social Media Template!';
                const body = `Hello, 
              
              Checkout this amazing social media template:
              
              ${baseUrl}/share/${vacancyId}
              
              Best regards,
              [Your Name]`;
              // Encode URI components to handle special characters
              const mailtoLink = `mailto:${encodeURIComponent(recipient)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
              
              // Open the mailto link in a new window
              window.location.href = mailtoLink;
            };
          
            
          
          const saveAndShare = async () => {
            try {
              const slides = document.querySelectorAll('.combined-box');
              const zip = new JSZip();
              const files = [];
              sendMail()
              // Create an array of promises for image generation
              const imagePromises = Array.from(slides).map((slide, index) =>
                htmlToImage.toPng(slide, { backgroundColor: 'white' }).then((dataUrl) => {
                  files.push(dataURLtoBlob(dataUrl));
                })
              );
          
              // Wait for all image promises to resolve
              await Promise.all(imagePromises);
          
              const formData = new FormData();
          
              // Append each file to formData
              files.forEach((file) => {
                formData.append('files', file); // Adding each file with the key "files"
              });
              formData.append("vacancyId", vacancyId)
          
              console.log('FormData ready:', formData);
          
              // Now dispatch the action with formData and await it
              await dispatch(saveAndShareAPI(formData));
            } catch (error) {
              console.error('Error converting images to blobs:', error);
            }
          };
          
          
  return (

    <Dialog onClose={onClose} open={open} className='startpopup'>
        <CancelIcon className="close" onClick={onClose}/>
        <Box>
            <Grid container spacing={2} >
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{background:"#FFE9D9"}}>
                    <Box>
                        <Box className='px-30'>
                            <Box className='mx-auto mt-30 mb-30' sx={{maxWidth:"440px"}}>
                                <Box className="glider-contain">
                                    <Box className="glider bg-white" ref={gliderRef}>
                                        <Box>
                                            <Box className='p-30'>
                                                <Typography variant='h4' className='fs-10 fw-500 text-color-c2 '>{template['LinkedIn Post 1']}</Typography>
                                              
                                                
                                            </Box>
                                            <Box className='combined-box'>
                                            <Box className='glider-img-box mt-auto'>
                                                    <Box className='layer d-flex' sx={{background:value?value:"rgb(12, 0, 129 , .6)"}}>
                                                        <Box className='m-auto px-30'>
                                                            <Typography variant='h4' className='fs-25 fw-700 text-white mb-20'>Vacature</Typography>
                                                            <Typography variant='h4' className='fs-15 fw-500 text-white mb-20'>{template?.Overlay_text[0]}</Typography>
                                                            <ul className='text-white no-before' style={{padding:"0 0 0 15px"}}>
                                                                <li className='fs-12 fw-600'>{template?.Overlay_text[1]} </li>
                                                                <li className='fs-12 fw-600'>{template?.Overlay_text[2]}</li>
                                                                <li className='fs-12 fw-600'>{template?.Overlay_text[3]} </li>
                                                            </ul>
                                                            <Typography variant='h4' className='fs-16 fw-500 text-orange'> <PlaceIcon className='text-white'/> 
                                                            {template?.Overlay_text[4]?.length>11?`${template?.Overlay_text[4]?.slice(0,10)}...`:`${template?.Overlay_text[4]?.slice(0,10)}`}</Typography>
                                                        </Box>
                                                    </Box>
                                                <img src={logo} className='logo'/>
                                                <img className='main-img' src={templateImages[0]} />
                                            </Box>
                                            {/* <Box className='d-flex gap-10 px-30 py-10'>
                                                <img src={logo} className='logo' style={{width:"auto", height:"52px"}} />
                                                <Divider className='mb-20'sx={{border:"2px solid #DEDEDE !important", borderColor:"#DEDEDE !important"}}></Divider>
                                                <Typography variant='h4' className='fs-13 fw-500 text-orange my-auto'>The Netherlands</Typography>
                                            </Box> */}
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box className='p-30'>
                                                <Typography variant='h4' className='fs-10 fw-500 text-color-c2 '>{template['LinkedIn Post 2']}</Typography>
                                                
                                            </Box>
                                            <Box className='combined-box mt-auto'>
                                            <Box className='glider-img-box '>
                                                    <Box className='layer d-flex' sx={{background:value?value:"rgb(12, 0, 129 , .6)"}}>
                                                        <Box className='m-auto px-30'>
                                                            <Typography variant='h4' className='fs-25 fw-700 text-white mb-20'>Vacature</Typography>
                                                            <Typography variant='h4' className='fs-15 fw-500 text-white mb-20'>{template?.Overlay_text[0]}</Typography>
                                                            <ul className='text-white no-before' style={{padding:"0 0 0 15px"}}>
                                                                <li className='fs-12 fw-600'>{template?.Overlay_text[1]} </li>
                                                                <li className='fs-12 fw-600'>{template?.Overlay_text[2]} </li>
                                                                <li className='fs-12 fw-600'>{template?.Overlay_text[3]}</li>
                                                            </ul>
                                                            <Typography variant='h4' className='fs-16 fw-500 text-orange'> <PlaceIcon className='text-white'/>  {template?.Overlay_text[4]?.length>11?`${template?.Overlay_text[4]?.slice(0,10)}...`:`${template?.Overlay_text[4]?.slice(0,10)}`}</Typography>
                                                        </Box>
                                                    </Box>
                                                <img src={logo} className='logo'/>
                                                <img className='main-img' src={templateImages[1]} />
                                            </Box>
                                            {/* <Box className='d-flex gap-10 px-30 py-10'>
                                                <img src={logo} className='logo' style={{width:"auto", height:"52px"}} />
                                                <Divider className='mb-20'sx={{border:"2px solid #DEDEDE !important", borderColor:"#DEDEDE !important"}}></Divider>
                                                <Typography variant='h4' className='fs-13 fw-500 text-orange my-auto'>The Netherlands</Typography>
                                            </Box> */}
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box className='p-30'>
                                                <Typography variant='h4' className='fs-10 fw-500 text-color-c2'>{template['LinkedIn Post 3']}</Typography>
                                                
                                            </Box>
                                            <Box className='combined-box mt-auto'>
                                            <Box className='glider-img-box '>
                                                    <Box className='layer d-flex' sx={{background:value?value:"rgb(12, 0, 129 , .6)"}}>
                                                        <Box className='m-auto px-30'>
                                                            <Typography variant='h4' className='fs-25 fw-700 text-white mb-20'>Vacature</Typography>
                                                            <Typography variant='h4' className='fs-15 fw-500 text-white mb-20'>{template?.Overlay_text[0]}</Typography>
                                                            <ul className='text-white no-before' style={{padding:"0 0 0 15px"}}>
                                                                <li className='fs-12 fw-600'>{template?.Overlay_text[1]} </li>
                                                                <li className='fs-12 fw-600'>{template?.Overlay_text[2]} </li>
                                                                <li className='fs-12 fw-600'>{template?.Overlay_text[3]}</li>
                                                            </ul>
                                                            <Typography variant='h4' className='fs-16 fw-500 text-orange'> <PlaceIcon className='text-white'/>  {template?.Overlay_text[4]?.length>11?`${template?.Overlay_text[4]?.slice(0,10)}...`:`${template?.Overlay_text[4]?.slice(0,10)}`}</Typography>
                                                        </Box>
                                                    </Box>
                                                <img src={logo} className='logo'/>
                                                <img className='main-img' src={templateImages[2]} />
                                            </Box>
                                            {/* <Box className='d-flex gap-10 px-30 py-10'>
                                                <img src={logo} className='logo' style={{width:"auto", height:"52px"}}/>
                                                <Divider className='mb-20'sx={{border:"2px solid #DEDEDE !important", borderColor:"#DEDEDE !important"}}></Divider>
                                                <Typography variant='h4' className='fs-13 fw-500 text-orange my-auto'>The Netherlands</Typography>
                                            </Box> */}
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box className='p-30'>
                                                <Typography variant='h4' className='fs-10 fw-500 text-color-c2'>{template['LinkedIn Post 4']}</Typography>
                                                
                                            </Box>
                                            <Box className='combined-box mt-auto'>
                                                <Box className='glider-img-box '>
                                                        <Box className='layer d-flex' sx={{background:value?value:"rgb(12, 0, 129 , .6)"}}>
                                                            <Box className='m-auto px-30'>
                                                                <Typography variant='h4' className='fs-25 fw-700 text-white mb-20'>Vacature</Typography>
                                                                <Typography variant='h4' className='fs-15 fw-500 text-white mb-20'>{template?.Overlay_text[0]}</Typography>
                                                                <ul className='text-white no-before' style={{padding:"0 0 0 15px"}}>
                                                                    <li className='fs-12 fw-600'>{template?.Overlay_text[1]} </li>
                                                                    <li className='fs-12 fw-600'>{template?.Overlay_text[2]} </li>
                                                                    <li className='fs-12 fw-600'>{template?.Overlay_text[3]}</li>
                                                                </ul>
                                                                <Typography variant='h4' className='fs-16 fw-500 text-orange'> <PlaceIcon className='text-white'/>  {template?.Overlay_text[4]?.length>11?`${template?.Overlay_text[4]?.slice(0,10)}...`:`${template?.Overlay_text[4]?.slice(0,10)}`}</Typography>
                                                            </Box>
                                                        </Box>
                                                    <img src={logo} className='logo'/>
                                                    <img className='main-img' src={templateImages[3]} />
                                                    
                                                </Box>
                                                {/* <Box className='d-flex gap-10 px-30 py-10'>
                                                    <img src={logo} className='logo' style={{width:"auto", height:"52px"}}/>
                                                    <Divider className='mb-20'sx={{border:"2px solid #DEDEDE !important", borderColor:"#DEDEDE !important"}}></Divider>
                                                    <Typography variant='h4' className='fs-13 fw-500 text-orange my-auto'>The Netherlands</Typography>
                                                </Box> */}
                                            </Box>
                                           
                                        </Box>
                                    </Box>
                                    <Box className='glider-btn-box'>
                                        <button aria-label="Previous" className="glider-prev"><KeyboardArrowLeftIcon/></button>
                                        <Divider className='mb-20'sx={{border:"2px solid #DEDEDE !important", borderColor:"#DEDEDE !important"}}></Divider>
                                        <button aria-label="Next" className="glider-next"><KeyboardArrowRightIcon/></button>
                                    </Box>
                                    <Box className='glider-btn-box number'>
                                        <button aria-label="Previous" className="glider-prev"> {activeIndex + 1} </button>
                                        <Divider className='mb-20'sx={{border:"2px solid #DEDEDE !important", borderColor:"#DEDEDE !important"}}></Divider>
                                        <button aria-label="Next" className="glider-next">4</button>
                                    </Box>
                                    <Box role="tablist" id="dots" className="dots"></Box>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className='d-flex'>
                    <Box className='m-auto p-30 '>
                        <Typography variant='h4' className='fs-18 fw-600 text-color-c1 mb-20'>Color Selection </Typography>
                        <Box className='fs-18 fw-600 d-flex gap-20 mb-20'>
                            <MuiColorInput value={value} onChange={handleColorChange} />
                            {/* <Button className='fs-16 text-black my-auto' sx={{minWidth:"fit-content !important",border:"1px solid #BFBABA",height:"28px",width:"28px"}}>
                                <ColorizeIcon sx={{fontSize:"17px"}} />
                            </Button> */}
                        </Box>
                        <Divider className='mb-20'sx={{border:"1.5px solid #DEDEDE !important"}}></Divider>
                        <Box className="gap-15 flex-column">
                            <Button onClick={regenerate} className='secondary-btn'>Regenerate Template</Button>
                            <Button onClick={()=>saveAndShare()} className='secondary-btn'>Save & Share</Button>
                            <Button onClick={generateAndDownloadTextFile} className='secondary-btn'>Download Social Media</Button>
                            {dowloadingImageAndFile ? <Button className='secondary-btn'><CircularProgress sx={{color:"#F89B53"}} size={24} /></Button> :
                            <Button onClick={handleDownloadImage} sx={{fontSize:"13px"}} className='secondary-btn'>Download Both Template</Button>}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Dialog>

  )
}

export default Socialmediapopup
